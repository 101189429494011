import { createSlice } from '@reduxjs/toolkit';
import { identifyLOB } from '../../utils';

const initialState = {
    lob: '',
    id: ''
};

const lobSlice = createSlice({
    name: 'lob',
    initialState,
    reducers: {
        setLob: (state, action) => {
            // Simply set the lob state in the reducer
            state.lob = action.payload;
        },
        setId: (state, action) => {
            // Action to set the ID separately
            state.id = action.payload;
        },
    },
});

// Export actions
export const { setLob, setId } = lobSlice.actions;

// Thunk for handling side effect asynchronously
export const initializeChaport = (lob) => async (dispatch) => {
    if (lob) {
        window.chaport.on('ready', function (data) {
            if (data.visitorId) {
                const id = data.visitorId;
                dispatch(setId(id));
                identifyLOB({ path: lob, id });
            }
        });
    }
};

// Export reducer
export default lobSlice.reducer;
