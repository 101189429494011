// components/LocationIdentifier.js
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { initializeChaport, setLob } from '../redux/reducers/lobSlice';

const LocationIdentifier = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    // Store the previous path to prevent multiple dispatches
    const previousPathRef = useRef(location.pathname);

    // Store visibility status to avoid redundant dispatches
    const hasDispatchedVisibility = useRef(false);

    const handleVisibilityChange = () => {
        try {
            if (document.visibilityState === 'hidden') {
                // If the document is not visible and we haven't already dispatched
                if (!hasDispatchedVisibility.current) {
                    dispatch(setLob("left"));
                    dispatch(initializeChaport("left"))
                    hasDispatchedVisibility.current = true; // Set flag to prevent further dispatches
                }
                return;
            } else {
                // Reset the visibility flag once the document becomes visible again
                hasDispatchedVisibility.current = false;
            }

            const currentPath = location.pathname.split('/').pop();
            if (currentPath === "") {
                dispatch(setLob("Homepage"));
                dispatch(initializeChaport("Homepage"))
            }
            // Only dispatch if the current path is different from the previous path
            if (currentPath !== previousPathRef.current) {
                dispatch(setLob(currentPath));
                dispatch(initializeChaport(currentPath))
                previousPathRef.current = location.pathname; // Update the previous path
            }

        } catch (error) {
            console.error("LocationIdentifier Error:", error);
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        handleVisibilityChange()

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [location, dispatch]); // Re-run when location changes

    return null;
};

export default LocationIdentifier;
