import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceApi } from '../ServiceApi';
import { setApiTOken } from '../Authreducer';

export const fetchLoginToken = createAsyncThunk(
    'auth/fetchLoginToken',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const result = await dispatch(
                ServiceApi.endpoints.Logintoken.initiate()
            ).unwrap(); // Unwrap to get the plain response or throw error
            console.log("result >>>???", result?.data)
            if (result?.data?.Token) {
                dispatch(setApiTOken(result?.data?.Token))
                return result.Token; // Return the token for the payload
            } else {
                dispatch(setApiTOken(""))
                throw new Error('Token not found in response');
            }
        } catch (error) {
            dispatch(setApiTOken(""))
            console.error('Error in fetchLoginToken:', error.message);
            return rejectWithValue(error.message); // Return error for failed thunk
        }
    }
);
