import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from ".";
import { setAuth } from "./redux/reducers/Authreducer";
import { fetchLoginToken } from "./redux/reducers/thunkservices/fetchtokend";

export const socketio = io;
const UserContext = createContext();

const UserContextAppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { APiToken } = useSelector((state) => state.Authreducer)
  console.log({ APiToken })
  const [UserData, setUserData] = useState({});
  const [usertoken, setToken] = useState(null);

  const handleSessionExpiry = () => {
    swal({
      title: "Error!",
      text: "Session Expired",
      icon: "error",
    }).then(() => {
      Logout();
    });
  };

  const Logout = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userdata");
    setToken(null);
    window.location.replace("/");
  };

  const getUserProfile = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/getCustomerProfile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(response?.data?.data);
    } catch (error) {
      if (error?.response?.status === 401) handleSessionExpiry();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("usertoken");
    setToken(token);
    dispatch(setAuth({ token: token || "" }));
    console.log("APiToken", APiToken)
    !APiToken && dispatch(fetchLoginToken());

    if (token) {
      console.log("Fetching user profile...");
      getUserProfile(token);
    } else {
      console.log("No user token found.");
    }
  }, []);

  const contextValue = {
    UserData,
    usertoken,
    Logout,
    setToken,
    setUserData,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const UseUserContext = () => {
  return useContext(UserContext);
};

export { UserContext, UserContextAppProvider, UseUserContext };
