import moment from "moment";
import { API_URL } from ".."
import axios from "axios";
const locationData = {
    "Motor": ["Chasisno"],
    "Medical": ["Individualpolicy"],
    "Travel": ["Traveldetails"],
    "Home": ["Homeinsurance"],
    "Yatch": ["Yachtdetails"],
    "OtherInsurance": ["Otherinsurance"],
    'Homepage': ['']
};
export const identifyLOB = (data) => {
    try {
        let currentPath = data?.path
        if (currentPath === "left") {
            handleViewingLob({ id: data?.id, matchedLob: "left" });
        }
        if (currentPath === "Homepage") {
            handleViewingLob({ id: data?.id, matchedLob: "Homepage" });
        }
        // Check which LOB the current page belongs to
        let matchedLob = null;
        for (const [lob, locations] of Object.entries(locationData)) {
            if (currentPath && locations.includes(currentPath)) {
                matchedLob = lob;
                break;
            }
        }
        if (matchedLob) {
            handleViewingLob({ id: data?.id, matchedLob });
        } else {

        }
    } catch (error) {
        console.log("Error in identifyLOB:", error);
    }
};
const vehicledetails = async (id) => {
    try {
        let response = await axios
            .post(API_URL + "/api/get_Motor_model_detailsbyid", {

                method: "post",
                ParamValue: id,
                headers: {
                    "Content-Type": "application/json",
                },
            }
            ).then((res) => res)
        if (Array.isArray(response?.data) && response?.data.length > 0) {
            response = response?.data[0]
            if (response?.bodTypes && Array.isArray(response?.bodTypes) && response?.bodTypes?.length > 0) {
                response.bodTypes = response.bodTypes[0]
            }
        }
        return response
    } catch (error) {
        return null
    }
}

const handleViewingLob = (data) => {
    try {
        const { matchedLob, id } = data
        let userType = 'unregistered'
        if (matchedLob && id) {
            const token = localStorage.getItem("usertoken")
            if (token) {
                userType = 'registered'
            }
            const requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    lob: matchedLob,
                    id: id,
                    reg_type: userType
                })
            };
            fetch(`${API_URL}/api/updateViewingLob`, requestOptions)
                .then(response => response.json())
                .then(data => data)
                .catch(error => console.log("API Error:", error));
        }
    } catch (error) {
        console.log("Error in handleViewingLob:", error);
    }
};
export const CreateQuatationDataPrepare = async (data) => {
    let formatedDateofbirth = moment(data.date_of_birth).format("DD/MM/YYYY")
    let today = new Date()
    today = moment(today).format("DD/MM/YYYY")
    let CarDetails = await vehicledetails(data.car_variant)
    console.log("CarDetails", CarDetails)
    data.minCarValue = 40000
    return {
        "SchemeCode": data?.last_year_policy_type?.toLowerCase()?.includes("comprehensive") ? "10001" : "10005",
        "ProdCode": data?.last_year_policy_type?.toLowerCase()?.includes("comprehensive") ? "10001" : "10005",
        "PolDivn": "10",
        "PolDept": "10",
        "CustType": data?.polcy_type?.toLowerCase()?.includes("individual") ? "I" : "C",
        "CustCode": "",
        "InsrName": data.name,
        "InsrDob": formatedDateofbirth,
        "InsrGender": "",
        "InsrNameAr": data.name,
        "InsrEmail": data.email,
        "InsrPOBox": "",
        "InsrEmirates": data.register_area || "",
        "InsrEmirateId": "",
        "InsrTradeList": "",
        "DrvrName": data.name,
        "InsrMobile": data.phoneno,
        "InsrPhoneNo": data.phoneno,
        "Location": data.vehicle_specification === "GCC" ? "01" : "00",
        "InsrNationality": data.nationality,
        "PolFmDt": today,
        "TaxRegnNo": "",
        "VehUse": "01",
        "VehMake": data.car_maker,
        "VehModel": data.car_model,
        "VehBodyType": CarDetails?.bodTypes.body_type_name,
        "VehRegion": data.vehicle_specification,
        "VehCylinder": CarDetails.motor_model_detail_cylinder,
        "NoOfPassenger": "5",
        "VehManfYear": data.model_year,
        "RegEmirate": data.register_area || "",
        "NoofDoors": "4",
        "VehValue": String(data.minCarValue),
        "VehBrandNewYn": data.buying_used_car ? "N" : "Y", // Add if applicable
        "VehDof": "",
        "VehEngineNo": "",
        "VehAgencyRepYn": data.repaire_type_name ? "Y" : "N",
        "VehDynaTradeYn": "N",
        "VehEngineCC": "",
        /* when i don't pass these fields i got an error  "message": "Bad control character in string literal in JSON at position 57"
        "VehLoadingCap": "02", // Add if applicable
        "VehPlateRegNo": "455454", // Add if applicable
        "VehPlateColor": "20", // Add if applicable
        */
        "VehLoadingCap": "02", // Add if applicable
        "VehPlateRegNo": "455454", // Add if applicable
        "VehPlateColor": "20", // Add if applicable
        "MortgageYn": "N",
        "Bank": "",
        "VehMileage": "",
        "SurveyYn": data?.leadid, // Add if applicable
        "IsTPLlastYrYn": data?.last_year_policy_type?.includes("TPL") ? "Y" : "N",
        "IsVehGCCYn": data.vehicle_specification === "GCC" ? "G" : "N",
        "IsInsrBreakYn": data.your_existing_policy_expired ? 'Y' : 'N',
        "IsMulkiyaExpYn": "N",
        // "VehxtTcfNo": "1234567890", // Add if applicable
        "DiscountYN": "Y",
        "VehRentACarYN": data?.additionalcovernames?.includes("rent") && data?.additionalcovernames?.includes("car") ? "Y" : "N",
        "ClmFreeYear": String(data.last_year_claim) || "",
        "slugBodyType": CarDetails.motor_model_detail_slug_body_type.map(item => item.name)
    }
}

